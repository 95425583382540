<template>
  <div>
    <h1 class="mb-5">{{ $route.name }}</h1>
    <b-card>
      <b-row no-gutters>
        <b-col cols="8" class="mb-5">
          <div class="row">
            <b-col cols="4">
              <b-input-group prepend="Cabang" class="mb-3">
                <b-form-select v-model="paging.cabang" :options="opt.cabang" @change="doGetPetugas()" />
              </b-input-group>
            </b-col>
            <b-col cols="4">
              <b-input-group prepend="Petugas" class="mb-3">
                <b-form-select v-model="paging.petugas" :options="opt.petugas" @change="doGetRembug()" />
              </b-input-group>
            </b-col>
            <b-col cols="4">
              <b-input-group prepend="Majelis" class="mb-3">
                <b-form-select v-model="paging.rembug" :options="opt.rembug" />
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group prepend="Dari Tanggal">
                <b-form-datepicker
                  v-model="paging.from"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="id"
                />
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group prepend="Sampai Tanggal">
                <b-form-datepicker
                  v-model="paging.to"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="id"
                />
              </b-input-group>
            </b-col>
          </div>
        </b-col>
        <b-col cols="4" class="d-flex justify-content-end align-items-start">
          <b-button-group>
            <b-button
              text="Button"
              variant="danger"
              @click="
                $bvModal.show('modal-pdf');
                doGetReport();
              "
            >
              PDF
            </b-button>
            <b-button text="Button" variant="success" @click="exportXls()">
              XLS
            </b-button>
            <b-button text="Button" variant="warning" @click="exportCsv()">
              CSV
            </b-button>
          </b-button-group>
        </b-col>
        <b-col cols="12">
          <b-table
            responsive
            bordered
            outlined
            small
            striped
            hover
            :fields="table.fields"
            :items="table.items"
            show-empty
            :emptyText="table.loading ? 'Memuat data...' : 'Tidak ada data'"
          >
            <template #cell(no)="item">
              {{ item.index + 1 }}
            </template>
            <template #cell(jumlah_pengajuan)="item">
              Rp {{ thousand(item.item.jumlah_pengajuan) }}
            </template>
            <template #cell(status_pengajuan)="item">
              {{ showStatus(item.item.status_pengajuan) }}
            </template>
          </b-table>
        </b-col>
        <b-col cols="12" class="justify-content-end d-flex">
          <b-pagination
            v-model="paging.page"
            :total-rows="table.totalRows"
            :per-page="paging.perPage"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      title="PREVIEW LAPORAN PENGAJUAN PEMBIAYAAN"
      id="modal-pdf"
      hide-footer
      size="xl"
      centered
    >
      <div id="table-print" class="p-5">
        <h5 class="text-center">
          KSPPS MITRA SEJAHTERA RAYA INDONESIA ( MSI )
        </h5>
        <h5 class="text-center">LAPORAN PENGAJUAN PEMBIAYAAN</h5>
        <h5 class="text-center" v-show="report.cabang">{{ report.cabang }}</h5>
        <h5 class="text-center" v-show="report.petugas">{{ report.petugas }}</h5>
        <h5 class="text-center" v-show="report.rembug">{{ report.rembug }}</h5>
        <h6 class="text-center mb-5 pb-5" v-show="report.from && report.to">
          Tanggal {{ dateFormatId(report.from) }} s.d
          {{ dateFormatId(report.to) }}
        </h6>
        <b-table
          responsive
          bordered
          outlined
          small
          striped
          hover
          :fields="report.fields"
          :items="report.items"
          show-empty
          :emptyText="report.loading ? 'Memuat data...' : 'Tidak ada data'"
          class="mt-5 pt-5 d-block"
        >
          <template #cell(no)="item">
            {{ item.index + 1 }}
          </template>
          <template #cell(jumlah_pengajuan)="item">
            Rp {{ thousand(item.item.jumlah_pengajuan) }}
          </template>
          <template #cell(status_pengajuan)="item">
            {{ showStatus(item.item.status_pengajuan) }}
          </template>
        </b-table>
      </div>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex justify-content-end border-top pt-5"
        >
          <b-button variant="secondary" @click="$bvModal.hide('modal-pdf')"
            >Cancel
          </b-button>
          <b-button
            variant="danger"
            type="button"
            class="ml-3"
            @click="doPrintPdf()"
          >
            Cetak PDF
          </b-button>
          <b-button
            variant="warning"
            type="button"
            class="ml-3"
            @click="doSavePdf()"
          >
            Simpan PDF
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
  
<script>
import html2pdf from "html2pdf.js";
import helper from "@/core/helper";
import { mapGetters } from "vuex";
import easycoApi from "@/core/services/easyco.service";
export default {
  name: "LaporanPengajuanPembiayaan",
  components: {},
  data() {
    return {
      table: {
        fields: [
          {
            key: "no",
            sortable: false,
            label: "No",
            thClass: "text-center w-5p",
            tdClass: "text-center",
          },
          {
            key: "nama_cabang",
            sortable: true,
            label: "Cabang",
            thClass: "text-center",
            tdClass: "",
          },
          {
            key: "tanggal_pengajuan",
            sortable: true,
            label: "Tanggal",
            thClass: "text-center",
            tdClass: "",
          },
          {
            key: "nama_anggota",
            sortable: true,
            label: "Nama",
            thClass: "text-center",
            tdClass: "",
          },
          {
            key: "nama_rembug",
            sortable: true,
            label: "Nama Majelis",
            thClass: "text-center",
            tdClass: "",
          },
          {
            key: "no_pengajuan",
            sortable: true,
            label: "No Pengajuan",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "jumlah_pengajuan",
            sortable: true,
            label: "Jumlah",
            thClass: "text-center",
            tdClass: "text-right",
          },
          {
            key: "status_pengajuan",
            sortable: true,
            label: "Status",
            thClass: "text-center",
            tdClass: "text-right",
          },
        ],
        items: [],
        loading: false,
        totalRows: 0,
      },
      report: {
        fields: [
          {
            key: "no",
            sortable: false,
            label: "No",
            thClass: "text-center w-5p",
            tdClass: "text-center",
          },
          {
            key: "nama_cabang",
            sortable: false,
            label: "Cabang",
            thClass: "text-center",
            tdClass: "",
          },
          {
            key: "tanggal_pengajuan",
            sortable: false,
            label: "Tanggal",
            thClass: "text-center",
            tdClass: "",
          },
          {
            key: "nama_anggota",
            sortable: false,
            label: "Nama",
            thClass: "text-center",
            tdClass: "",
          },
          {
            key: "nama_rembug",
            sortable: false,
            label: "Nama Majelis",
            thClass: "text-center",
            tdClass: "",
          },
          {
            key: "no_pengajuan",
            sortable: false,
            label: "No Pengajuan",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "jumlah_pengajuan",
            sortable: false,
            label: "Jumlah",
            thClass: "text-center",
            tdClass: "text-right",
          },
          {
            key: "status_pengajuan",
            sortable: false,
            label: "Status",
            thClass: "text-center",
            tdClass: "text-right",
          },
        ],
        items: [],
        loading: false,
        totalRows: 0,
        cabang: null,
        petugas: null,
        rembug: null,
        from: null,
        to: null,
      },
      paging: {
        page: 1,
        perPage: 10,
        sortDesc: true,
        sortBy: "kop_anggota.id",
        search: "",
        status: [0, 1],
        cabang: null,
        petugas: null,
        rembug: null,
        from: null,
        to: null,
      },
      opt: {
        cabang: [],
        petugas: [],
        rembug: [],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    paging: {
      handler(val) {
        this.doGet();
      },
      deep: true,
    },
  },
  mounted() {
    this.doGet();
    this.doGetCabang();
    this.doGetPetugas();
    this.doGetRembug();
    this.doGetReport();
  },
  methods: {
    ...helper,
    doPrintPdf() {
      let filename = "LAPORAN PENGAJUAN PEMBIAYAAN";
      if (this.report.cabang) {
        filename += ` - Cabang ${this.report.cabang}`;
        filename += ` - Petugas ${this.report.petugas}`;
        filename += ` - Majelis ${this.report.rembug}`;
      }
      if (this.report.from && this.report.to) {
        filename += ` - Dari ${this.dateFormatId(
          this.report.from
        )} Sampai ${this.dateFormatId(this.report.to)}`;
      }
      let element = document.getElementById("table-print");
      let options = {
        margin: 0,
        filename: `${filename}.pdf`,
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "landscape",
        },
      };
      html2pdf()
        .set(options)
        .from(element)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          console.log("hi");
          window.open(pdf.output("bloburl"), "_blank");
        });
    },
    doSavePdf() {
      let filename = "LAPORAN PENGAJUAN PEMBIAYAAN";
      if (this.report.cabang) {
        filename += ` - Cabang ${this.report.cabang}`;
        filename += ` - Petugas ${this.report.petugas}`;
        filename += ` - Majelis ${this.report.rembug}`;
      }
      if (this.report.from && this.report.to) {
        filename += ` - Dari ${this.dateFormatId(
          this.report.from
        )} Sampai ${this.dateFormatId(this.report.to)}`;
      }

      html2pdf(document.getElementById("table-print"), {
        margin: 0,
        filename: `${filename}.pdf`,
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "landscape",
        },
      });
    },
    async exportXls() {
      let payload = `kode_cabang=${this.paging.cabang}&jenis_pembiayaan=9&kode_petugas=~&kode_rembug=~&from_date=${this.paging.from}&thru_date=${this.paging.to}`;
      let req = await easycoApi.pengajuanPembiayaanExcel(payload);
      console.log(req.data);
      const url = window.URL.createObjectURL(new Blob([req.data]));
      const link = document.createElement("a");
      let fileName = "Pengajuan_Pembiayaan.xls";
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },
    async exportCsv() {
      let payload = `kode_cabang=${this.paging.cabang}&jenis_pembiayaan=9&kode_petugas=~&kode_rembug=~&from_date=${this.paging.from}&thru_date=${this.paging.to}`;
      let req = await easycoApi.pengajuanPembiayaanCsv(payload);
      console.log(req.data);
      const url = window.URL.createObjectURL(new Blob([req.data]));
      const link = document.createElement("a");
      let fileName = "Pengajuan_Pembiayaan.csv";
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },
    getCabangName(id) {
      if (id > 0) {
        let cabangName = this.opt.cabang.find((i) => i.value == id);
        if (cabangName) {
          console.log(cabangName.text);
          return cabangName.text;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    async doGetCabang() {
      this.opt.cabang = [];
      let payload = {
        perPage: "~",
        page: 1,
        sortBy: "nama_cabang",
        sortDir: "ASC",
        search: "",
      };
      try {
        let req = await easycoApi.cabangRead(payload, this.user.token);
        let { data, status, msg } = req.data;
        if (status) {
          this.opt.cabang = [
            {
              value: 0,
              text: "All",
            },
          ];
          data.map((item) => {
            this.opt.cabang.push({
              value: item.kode_cabang,
              text: `${item.kode_cabang} - ${item.nama_cabang}`,
            });
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async doGetPetugas() {
      this.opt.petugas = [];
      let payload = {
        page: 1,
        perPage: "~",
        sortBy: "kode_pgw",
        search: "",
        sortyDir: "ASC",
        kode_cabang: this.paging.cabang,
      };
      try {
        let req = await easycoApi.pegawaiRead(payload, this.user.token);
        let { data, status, msg, total } = req.data;
        if (status) {
          this.opt.petugas = [{
            value: 0,
            text: "All",
          },

          ];
          data.map((item) => {
            this.opt.petugas.push({
              value: item.kode_pgw,
              text: `${item.kode_pgw} - ${item.nama_pgw}`,
            });
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async doGetRembug() {
      this.opt.rembug = [];
      let payload = {
        page: 1,
        perPage: "~",
        sortBy: "kode_rembug",
        search: "",
        sortyDir: "ASC",
        kode_cabang: this.paging.cabang,
        kode_petugas: this.paging.petugas,
      };
      try {
        let req = await easycoApi.anggotaRembug(payload, this.user.token);
        let { data, status, msg } = req.data;
        if (status) {
          this.opt.rembug = [
            {
              value: 0,
              text: "All",
            },
          ];
          data.map((item) => {
            this.opt.rembug.push({
              value: Number(item.kode_rembug),
              text: item.nama_rembug,
            });
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async doGet() {
      let payload = this.paging;
      payload.sortDir = payload.sortDesc ? "DESC" : "ASC";
      payload.perPage = 10;
      this.table.loading = true;
      try {
        let req = await easycoApi.pengajuanRead(payload, this.user.token);
        let { data, status, msg, total } = req.data;
        if (status) {
          this.table.items = data;
          this.table.totalRows = total;
        } else {
          this.notify("danger", "Error", msg);
        }
        this.table.loading = false;
      } catch (error) {
        this.table.loading = false;
        console.error(error);
        this.notify("danger", "Login Error", error);
      }
    },
    async doGetReport() {
      let payload = {...this.paging};
      payload.sortDir = payload.sortDesc ? "DESC" : "ASC";
      payload.perPage = "~";
      this.report.loading = true;
      this.report.from = payload.from;
      this.report.to = payload.to;
      this.report.cabang = this.getCabangName(payload.cabang);
      try {
        console.log('report',payload)
        let req = await easycoApi.pengajuanRead(payload, this.user.token);
        let { data, status, msg, total } = req.data;
        if (status) {
          this.report.items = data;
          this.report.totalRows = total;
        } else {
          this.notify("danger", "Error", msg);
        }
        this.report.loading = false;
      } catch (error) {
        this.report.loading = false;
        console.error(error);
        this.notify("danger", "Error", error);
      }
    },
    showStatus(status) {
      let statusText = "";
      switch (status) {
        case 1:
          statusText = "Aktivasi";
          break;
        case 2:
          statusText = "Ditolak";
          break;
        case 3:
          statusText = "Batal";
          break;
        default:
          statusText = "Registrasi";
          break;
      }
      return statusText;
    },
    doInfo(msg, title, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right",
      });
    },
  },
};
</script>
